import { LANDING_DESCRIPTION, LANDING_TITLE } from '@/utils/messages';
import { getTrackIdFromStorage } from '@/utils/utils';
import { CircularProgress, Typography } from '@mui/material';
import { Landing } from '@verifime/components';
import tokens from '@verifime/design-tokens';
import { useRouter } from 'next/router';
import { ACTIVATE_TRACK_ID_PATH } from './activate-track-id';
import { CREDENTIAL_SETUP_PATH } from './credentialSetup';
import { useEffect } from 'react';

export const INITIALISE_SESSION = '/initialise-session';

export default function InitialiseSession() {
  const router = useRouter();
  const trackId = getTrackIdFromStorage();
  const from = router.query['from'] as string;

  useEffect(() => {
    if (trackId) {
      router.push(ACTIVATE_TRACK_ID_PATH);
      return;
    }

    // Redirect to previous url if there it is
    if (from) {
      router.push(decodeURIComponent(from));
      return;
    }

    router.push(CREDENTIAL_SETUP_PATH);
  }, [router, from, trackId]);

  return (
    <Landing title={LANDING_TITLE} description={LANDING_DESCRIPTION}>
      <CircularProgress color="inherit" size={tokens.fontSizeBase} />
      <Typography variant="body1">Please wait</Typography>
    </Landing>
  );
}
