'use client';

import { LANDING_DESCRIPTION, LANDING_TITLE } from '@/utils/messages';
import {
  clearLastAuthEmailFromUrl,
  clearTrackIdFromStorage,
  getActionFromUrl,
  getLastAuthEmailFromUrl,
  getTrackIdFromStorage,
  getTrackIdFromUrl,
  saveTrackIdToStorage,
} from '@/utils/utils';
import { default as SuccessIcon } from '@mui/icons-material/CheckCircleRounded';
import { PasswordlessLogin } from '@verifime/cognito-passwordless-auth';
import { Landing } from '@verifime/components';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { INITIALISE_SESSION } from './initialise-session';

export default function LoginPage() {
  const router = useRouter();

  useEffect(() => {
    // Sign in by magic link,
    // it may include the trackId if the link is generated for invitation
    const trackId = getTrackIdFromUrl();
    if (trackId) {
      saveTrackIdToStorage(trackId);
    }
  }, []);

  const signInRedirect = () => {
    router.push({
      pathname: INITIALISE_SESSION,
      query: { ...router.query },
    });
  };

  const expiredLinkRedirect = () => {
    const trackId = getTrackIdFromUrl();
    // No trackId means that it is not the sign-up flow and we
    // currently only focus on sign-up link expired,
    // thus keep in the login page if no trackId
    if (!trackId) {
      return;
    }

    const action = getActionFromUrl();
    // We need this action to recognise that the url is redirected from
    // the recover-invitation and ask to login
    if (action === 'login') {
      return;
    }

    router.push(`/recover-invitation/${trackId}`);
  };

  const getMagicLinkReriectUrl = async () => {
    const origin = location.origin;
    let trackId = getTrackIdFromUrl();
    if (!trackId) {
      trackId = getTrackIdFromStorage();
    }
    if (!trackId) {
      return origin;
    }

    return `${origin}?trackId=${trackId}`;
  };

  return (
    <Landing title={LANDING_TITLE} description={LANDING_DESCRIPTION}>
      <PasswordlessLogin
        signInCallback={signInRedirect}
        getMegicLinkRedirectUri={getMagicLinkReriectUrl}
        onSignInAsDifferentUser={() => {
          clearTrackIdFromStorage();
          clearLastAuthEmailFromUrl();
        }}
        expiredLinkCallback={expiredLinkRedirect}
        initialEmail={getLastAuthEmailFromUrl()}
      >
        <SuccessIcon color="success" />
      </PasswordlessLogin>
    </Landing>
  );
}
