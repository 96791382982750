import { isServer } from '@verifime/utils';

const DISMISSED_RECOVERY_METHOD_ALERT = 'dismissedRecoveryMethodAlert';
const LAST_DISMISSED_PERMISSION_REQUEST_ID = 'lastDismissedPermissionRequestId';
const ASSOCIATED_PARTY_ASSIST_OPT_OUT_FLAGGED = 'associatedPartyAssistOptOutFlagged';
const TRACK_ID_ACTION_NEXT_PAGE = 'trackIdActionNextPage';

export function parsedUrl() {
  const hash = location.hash;
  const urlParams = new URLSearchParams(location.search);
  return { hash, urlParams };
}

export function getTrackIdFromUrl() {
  const { urlParams } = parsedUrl();
  return urlParams.get('trackId');
}

export function getActionFromUrl() {
  const { urlParams } = parsedUrl();
  return urlParams.get('action');
}

const LAST_AUTH_EMAIL_URL_KEY = 'lastAuthEmail';

export function getLastAuthEmailFromUrl() {
  if (isServer) {
    return;
  }
  const { urlParams } = parsedUrl();
  return urlParams.get(LAST_AUTH_EMAIL_URL_KEY);
}

export function clearLastAuthEmailFromUrl() {
  const url = new URL(location.href);
  url.searchParams.delete(LAST_AUTH_EMAIL_URL_KEY);
  history.replaceState(null, '', url.href);
}

export function getSignUpCodeFromUrl() {
  const { urlParams } = parsedUrl();
  return urlParams.get('signUpCode');
}

const TRACK_ID_KEY = 'TRACK_ID';

export function saveTrackIdToStorage(trackId: string) {
  sessionStorage.setItem(TRACK_ID_KEY, trackId);
}

export function clearTrackIdFromStorage() {
  sessionStorage.removeItem(TRACK_ID_KEY);
}

export function getTrackIdFromStorage() {
  if (isServer) {
    return null;
  }
  return sessionStorage.getItem(TRACK_ID_KEY);
}

export function getDismissedRecoveryMethodAlert() {
  if (isServer) {
    return null;
  }
  return sessionStorage.getItem(DISMISSED_RECOVERY_METHOD_ALERT);
}

export function setDismissedRecoveryMethodAlertToTrue() {
  sessionStorage.setItem(DISMISSED_RECOVERY_METHOD_ALERT, 'true');
}

export function getLastDismissedPermissionRequestId() {
  if (isServer) {
    return null;
  }
  return sessionStorage.getItem(LAST_DISMISSED_PERMISSION_REQUEST_ID);
}

export function setLastDismissedPermissionRequestId(permissionRequestId: string) {
  sessionStorage.setItem(LAST_DISMISSED_PERMISSION_REQUEST_ID, permissionRequestId);
}

function getAssociatedPartyAssistOptOutFlag(): string[] {
  if (isServer) {
    return [];
  }
  const asJsonStr = sessionStorage.getItem(ASSOCIATED_PARTY_ASSIST_OPT_OUT_FLAGGED);
  return asJsonStr ? JSON.parse(asJsonStr) : [];
}

export function hasOptedOutAssoicatedPartyAssist(customerId: string) {
  const associatedPartyAssistOptOutFlagged = getAssociatedPartyAssistOptOutFlag();
  return associatedPartyAssistOptOutFlagged.includes(customerId);
}

export function optOutAssociatedPartyAssist(customerIdToBeFlagged: string) {
  if (!isServer && !hasOptedOutAssoicatedPartyAssist(customerIdToBeFlagged)) {
    const associatedPartyAssistOptOutFlagged = getAssociatedPartyAssistOptOutFlag();
    associatedPartyAssistOptOutFlagged.push(customerIdToBeFlagged);
    sessionStorage.setItem(
      ASSOCIATED_PARTY_ASSIST_OPT_OUT_FLAGGED,
      JSON.stringify(associatedPartyAssistOptOutFlagged),
    );
  }
}

export function optInAssociatedPartySetupAssist(customerIdToBeRemoved: string) {
  if (!isServer && hasOptedOutAssoicatedPartyAssist(customerIdToBeRemoved)) {
    const associatedPartyAssistOptOutFlagged = getAssociatedPartyAssistOptOutFlag();
    const updatedAssociatedPartyAssistOptOutFlagged = associatedPartyAssistOptOutFlagged.filter(
      (customerId) => customerId !== customerIdToBeRemoved,
    );
    sessionStorage.setItem(
      ASSOCIATED_PARTY_ASSIST_OPT_OUT_FLAGGED,
      JSON.stringify(updatedAssociatedPartyAssistOptOutFlagged),
    );
  }
}

export function setTrackIdActionNextPage(nextPage: string) {
  sessionStorage.setItem(TRACK_ID_ACTION_NEXT_PAGE, nextPage);
}

export function getTrackIdActionNextPage() {
  if (isServer) {
    return null;
  }
  return sessionStorage.getItem(TRACK_ID_ACTION_NEXT_PAGE);
}

export function clearTrackIdActionNextPage() {
  sessionStorage.removeItem(TRACK_ID_ACTION_NEXT_PAGE);
}
