import { NAV_ITEM_HOME, WIZARD_PATH_INDIVIDUAL } from '@/path';
import { api } from '@/utils/api';
import { LANDING_DESCRIPTION, LANDING_TITLE } from '@/utils/messages';
import {
  clearTrackIdFromStorage,
  getTrackIdFromStorage,
  setTrackIdActionNextPage,
} from '@/utils/utils';
import { CircularProgress, Typography } from '@mui/material';
import { Landing } from '@verifime/components';
import tokens from '@verifime/design-tokens';
import { useFeatureToggle } from '@verifime/utils';
import { useRouter } from 'next/router';
import { useEffect, useState, useRef } from 'react';
import { CREDENTIAL_SETUP_PATH } from './credentialSetup';

export const ACTIVATE_TRACK_ID_PATH = '/activate-track-id';

export default function ActivateTrackId() {
  const { isSetupCredentialNotAfterActivateTrackId } = useFeatureToggle();
  const router = useRouter();
  const [nextPage, setNextPage] = useState<string>(undefined);
  const initialized = useRef(false); // the activation api should only be called once only, so use ref to track if it's already called
  const trackId = getTrackIdFromStorage();

  useEffect(() => {
    if (initialized.current) {
      return;
    }

    initialized.current = true;
    let activationPromise;
    if (trackId) {
      activationPromise = api
        .patchV1trackingTrackingReferenceactivate(undefined, {
          params: { trackingReference: trackId },
        })
        .then((response) => {
          clearTrackIdFromStorage();
          return response;
        });
    } else {
      activationPromise = Promise.resolve();
    }

    const hasIdentitiesPromise = api
      .getV1customerme({
        queries: {
          recursive: false,
        },
      })
      .then((response) => {
        return response.customerList?.length > 0;
      });

    Promise.all([activationPromise, hasIdentitiesPromise])
      .then(([activationResponse, hasIdentities]) => {
        const from = router.query['from'] as string;
        if (activationResponse) {
          if (activationResponse.type === 'SIGN_UP') {
            if (hasIdentities) {
              // redirect to home page
              setNextPage(`${NAV_ITEM_HOME.path}`);
            } else {
              setNextPage(`${WIZARD_PATH_INDIVIDUAL}`);
            }
          } else if (activationResponse.type === 'TASK') {
            setNextPage(`/task/${activationResponse.taskId}`);
          }
        } else if (from) {
          setNextPage(decodeURIComponent(from));
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setNextPage(NAV_ITEM_HOME.path);
      });
  }, [router.query, trackId]);

  if (nextPage) {
    if (isSetupCredentialNotAfterActivateTrackId) {
      router.push(nextPage);
    } else {
      setTrackIdActionNextPage(nextPage);
      router.push(CREDENTIAL_SETUP_PATH);
    }
    return;
  }

  return (
    <Landing title={LANDING_TITLE} description={LANDING_DESCRIPTION}>
      {!nextPage && (
        <>
          <CircularProgress color="inherit" size={tokens.fontSizeBase} />
          <Typography variant="body1">Please wait</Typography>
        </>
      )}
    </Landing>
  );
}
